import React from 'react';
import { Layout } from 'antd';
import classnames from 'classnames/bind';
import useAuth from 'hooks/useAuth';
import { useWeb3React } from '@web3-react/core';

import Menu from './Menu'
import WalletModal from 'widgets/WalletModal';

import styles from './index.module.scss';

const cx = classnames.bind(styles);

const Layouts: React.FC = ({ children }) => {
  const { login, logout } = useAuth();
  const { account } = useWeb3React();

  return (
    <Layout className={cx('layout')}>
      <header className={cx('header')}>
        <div>Chip</div>
        <WalletModal
          login={login}
          logout={logout}
          account={account}
        />
      </header>
      <main className={cx('content')}>
        {children}
      </main>
    </Layout>
  )
}

export default Layouts