import React from "react";
import Svg from "components/Svg";
import { SvgProps } from "components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 60 60" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M26.2201 31.7118C26.3935 31.812 26.6067 31.812 26.7802 31.7118L30.8705 29.3503C31.0439 29.2501 31.1511 29.065 31.1511 28.8652V24.1416C31.1511 23.9412 31.0439 23.7567 30.8705 23.6565L26.7802 21.295C26.6067 21.1948 26.3935 21.1948 26.2201 21.295L22.1297 23.6565C21.9563 23.7567 21.8491 23.9412 21.8491 24.1416V28.8652C21.8491 29.065 21.9563 29.2501 22.1297 29.3503L26.2201 31.7118Z" fill="url(#paint0_linear)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M42.7779 35.2524C42.7779 35.6532 42.5647 36.0222 42.2179 36.2226L34.6705 40.5818C34.4104 40.7324 34.0899 40.7324 33.8304 40.5818L30.3745 38.5865L38.0619 34.1487C38.5821 33.8488 38.9026 33.2934 38.9026 32.6929V20.3129C38.9026 19.713 38.5821 19.1576 38.0619 18.8577L27.276 12.629C26.7968 12.3519 26.2056 12.3519 25.7258 12.6278V8.15375L25.9397 8.03013C26.2865 7.82978 26.7136 7.82978 27.0604 8.03013L42.2179 16.7814C42.5647 16.9818 42.7779 17.3515 42.7779 17.7522V35.2524ZM27.0604 44.9757C26.7136 45.1754 26.2865 45.1754 25.9397 44.9757L10.7828 36.2238C10.436 36.0234 10.2221 35.6543 10.2221 35.2536V17.7522C10.2221 17.3515 10.436 16.9818 10.7828 16.7814L14.098 14.8675V32.6929C14.098 33.2934 14.4185 33.8488 14.9381 34.1487L30.3745 43.0617L27.0604 44.9757ZM44.9731 13.8968L28.1823 4.20049C27.1418 3.6 25.86 3.6 24.8195 4.20049L21.8487 5.917V6.03183V10.3911V14.8663L21.8476 14.8669L21.8487 19.3427L26.2197 16.8189C26.3931 16.7188 26.6064 16.7188 26.7798 16.8189L34.7466 21.4184C34.92 21.5186 35.0267 21.7037 35.0267 21.9035V31.1023C35.0267 31.3027 34.92 31.4872 34.7466 31.5874L26.7798 36.1869C26.6064 36.287 26.3931 36.287 26.2197 36.1869L18.254 31.5874C18.0806 31.4872 17.9734 31.3027 17.9734 31.1023V8.15433L8.02756 13.8968C6.9871 14.4972 6.34619 15.6068 6.34619 16.8084V36.1974C6.34619 37.3984 6.9871 38.5085 8.02814 39.109L24.8207 48.8C25.86 49.3999 27.1412 49.3999 28.1811 48.8L44.9725 39.109C46.0124 38.5085 46.6533 37.399 46.6533 36.1974V16.8078C46.6533 15.6068 46.0129 14.4972 44.9731 13.8968Z" fill="url(#paint1_linear)"/>
        <defs>
        <linearGradient id="paint0_linear" x1="26.5001" y1="21.2198" x2="26.5001" y2="31.787" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6F65FC"/>
        <stop offset="1" stopColor="#4B31D1"/>
        </linearGradient>
        <linearGradient id="paint1_linear" x1="26.4997" y1="3.75012" x2="26.4997" y2="49.25" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6F65FC"/>
        <stop offset="1" stopColor="#4B31D1"/>
        </linearGradient>
        </defs>
    </Svg>
  );
};

export default Icon;
