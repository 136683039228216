import React, { lazy } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import BigNumber from 'bignumber.js'
import Layout from 'components/Layouts'
import SuspenseWithChunkError from 'components/SuspenseWithChunkError'
import history from './routerHistory'
import { Spin } from 'antd';
import useEagerConnect from 'hooks/useEagerConnect';

// Route-based code splitting
// Only pool is included in the main bundle because of it's the most visited page
const Home = lazy(() => import('./views/Home'))

// This config is required for number formatting
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

const App: React.FC = () => {

  useEagerConnect();
  
  return (
    <Router history={history}>
      <Layout>
        <SuspenseWithChunkError fallback={<Spin />}>
          <Switch>
            <Route path="/" exact>
              <Home />
            </Route>
            <Route component={Home} />
          </Switch>
        </SuspenseWithChunkError>
      </Layout>
    </Router>
  )
}

export default React.memo(App)
